module.exports = {
  authBan: true,
  fastpay: true,
  woocommerce: true,
  mailToPatientForNewAlert: true,
  patientsOrientedMessages: true,
  selectMessageTypeOnExtranet: true,
  messaging: true,
  sms: true,
  telesuiviAlerts: true
};
